import { cn } from '@hapstack/common'
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'

export const ToggleGroup = ({
  className,
  children,
  ...props
}: ToggleGroupPrimitive.ToggleGroupSingleProps) => {
  return (
    <ToggleGroupPrimitive.Root
      {...props}
      className={cn(
        'inline-flex rounded border border-border text-sm',
        className
      )}
    >
      {children}
    </ToggleGroupPrimitive.Root>
  )
}
export const ToggleGroupItem = ({
  children,
  className,
  ...props
}: ToggleGroupPrimitive.ToggleGroupItemProps) => (
  <ToggleGroupPrimitive.ToggleGroupItem
    {...props}
    className={cn(
      'group ml-px flex min-w-9 items-center justify-center bg-white px-2 py-1.5 font-medium text-muted-foreground/50 transition-colors duration-300 first:ml-0 first:rounded-bl first:rounded-tl last:rounded-br last:rounded-tr hover:bg-secondary/50 data-[state=on]:bg-gray-200 data-[state=on]:text-primary',
      className
    )}
  >
    {children}
  </ToggleGroupPrimitive.ToggleGroupItem>
)
